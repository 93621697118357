<template>
  <div>
    <div class="container mx-auto md:pt-20">
      <div class="max-w-4xl mx-auto">
        <div class="md:flex items-center md:-ml-4 md:-mr-4 pt-6 md:pt-0">
          <div class="md:w-9/12 md:px-4 md:pr-12 mb-8 md:mb-0">
            <headline class="font-bold">
              {{ data.headline }}
            </headline>
            <div
              class="text-xl mt-4 text-muted"
              v-if="data.description">
              {{ data.description }}
            </div>
          </div>
          <div class="md:w-3/12 md:px-4">
            <div class="flex items-center md:flex-col">
              <figure class="w-2/6 md:w-auto">
                <img
                  src="~@/assets/images/harald-intro.png"
                  class="mx-auto"
                  alt="Harald">
              </figure>
              <div class="w-4/6 md:w-auto md:mt-8">
                <ul class="text-center">
                  <li><span class="text-red-600 inline-block mr-2">+</span> Tar kun 5 minutter</li>
                  <li><span class="text-red-600 inline-block mr-2">+</span> 299,- per e-resept</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto pt-8 pb-12 md:py-12">
      <div class="max-w-4xl mx-auto">
        <h2 class="inline-block text-xl mb-4 font-bold text-gray-800">
          Dette må du samtykke før vi starter
        </h2>
        <input-checkbox
          v-model="data.model"
          :label="data.field.title"
          label-class="font-bold" />
      </div>
    </div>

    <div class="pb-12">
      <slot :is-valid="isValid" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    isValid () {
      return this.data.model === true
    }
  }
}
</script>
